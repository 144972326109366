import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';

import { BoxButton } from './style';

interface Props {
  window?: () => Window;
  bottom?: number;
  right?: number;
  scrollDuration?: number;
}

export default function ScrollTop(props: Props) {
  const { bottom = 60, right = 20 } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleScrollToTop = () => {
    import('react-scroll').then((scroll) => {
      scroll.animateScroll.scrollToTop({
        duration: props?.scrollDuration,
      });
    });
  };

  return (
    <Zoom in={trigger}>
      <BoxButton bottom={bottom} right={right} onClick={handleScrollToTop}>
        <Fab>
          <KeyboardArrowUpIcon sx={{ width: 44, height: 44 }} />
        </Fab>
      </BoxButton>
    </Zoom>
  );
}
