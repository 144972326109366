import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const BoxButton = styled(Box)(
  (props) => `
  z-index: 10;
  position: fixed; 
  bottom: ${props.bottom}px;
  right: ${props.right}px;
  
  button {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
    svg {
      fill: ${props.theme.palette.primary.main};
      color: ${props.theme.palette.primary.main};
    }
    
    &.MuiFab-root {
      @media (hover: none) {
        background-color: #FFFFFF;
      }
    }
  }
`,
);
